/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

 $(function () {
    'use strict';

    // Display random image from auth views
    if ($('.image-random').length) {
        let random = Math.floor(Math.random() * (6 - 1 + 1) + 1);
        $('.image-random').attr('src', '/images/auth/' + random + '.png');
    }
 });
