/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(function () {
    'use strict';

    /*
     | Script to make tab work on the site.
     */

    // Add class 'active' for show tab content.
    $('a[data-toggle="tab"]').click((e) => {
        e.preventDefault();
        let tabTarget = $(e.currentTarget).attr('data-target'),
            tabItem = $(e.currentTarget).attr('href');

        $('a[data-toggle="tab"]').removeClass('active');
        $(e.currentTarget).toggleClass('active');

        $('[data-tab="' + tabTarget + '"]').each((i, t) => {
            $(t).removeClass('show');
        });

        // Check tabs for display if has class or ID selector.
        if ($(e.currentTarget).attr('data-selector') == 'class') {
            tabItem = tabItem.substr(1);
            $('.' + tabItem).addClass('show');
        } else {
            $(tabItem).addClass('show');
        }
    });

    // Check if tabs responsive class.
    if ($('[data-tab]').length) {
        $('[data-tab][data-responsive]').each(function (i, e) {
            let size = $(e).attr('data-responsive');

            if (size == 'gt' && $(document).width() > 1365) {
                $(e).addClass('show');
            } else if (size == 'xl' && $(document).width() < 1366) {
                $(e).addClass('show');
            } else if (size == 'lg' && $(document).width() < 1280) {
                $(e).addClass('show');
            } else if (size == 'md' && $(document).width() < 1024) {
                $(e).addClass('show');
            } else if (size == 'sm' && $(document).width() < 768) {
                $(e).addClass('show');
            } else if (size == 'xs' && $(document).width() < 640) {
                $(e).addClass('show');
            }
        });
    }
});
