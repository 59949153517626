require('jssocials');

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

$(function () {
    'use strict';
    /**
     * Jssocials
     * @link http://js-socials.com/
     */
    $('#article-socials-share').jsSocials({
        shareIn: 'popup',
        showLabel: false,
        showCount: false,
        shares: [
            {
                share: 'twitter',
                logo: '/images/socials/twitter.svg',
            },
            {
                share: 'pinterest',
                logo: '/images/socials/pinterest.svg',
            },
            {
                share: 'facebook',
                logo: '/images/socials/facebook.svg',
            },
            {
                share: 'whatsapp',
                logo: '/images/socials/whatsapp.svg',
            },
        ],
    });
});
